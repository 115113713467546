import React from 'react'
import Helmet from 'react-helmet'
import Contact from '../components/Contact'
import Layout from '../components/layout'
import Address from '../components/Address'

const Page = () => {
  const siteTitle =
    'Psychoterapia Mosty - psychoterapia szkoleniowa we Wrocławiu'
  const siteDescription =
    'Psychoterapia Wrocław, doświadczeni psychoterapeuci. Psychoterapia szkoleniowa.'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>
              Psychoterapia szkoleniowa Polskiego Towarzystwa Psychiatrycznego
            </h2>
          </header>
        </section>

        <section>
          <p>
            W MOSTACH istnieje możliwość przejścia indywidualnej psychoterapii
            szkoleniowej uznawanej przez Polskie Towarzystwo Psychiatryczne.
            Osoby uprawnione do prowadzenia psychoterapii szkoleniowej:
          </p>
          <ul>
            <li>Agata Świst (nr certyfikatu 643)</li>
            <li>Jadwiga Wojciechowska (nr certyfikatu 988)</li>
          </ul>
        </section>

        <section id="three">
          <Address />
        </section>
      </div>
    </Layout>
  )
}

export default Page
